export class CommonTab {
    constructor(wrapper) {
        this.wrapper = wrapper;
        this.trg = this.wrapper.getElementsByClassName(CommonTab.trgClassName);
        this.bindEvent();
    }
    bindEvent() {
        if (this.trg.length) {
            for (let i = 0; i < this.trg.length; i++) {
                if (!(this.trg[i] instanceof HTMLElement)) {
                    return;
                }
                const thisTrg = this.trg[i];
                thisTrg.addEventListener('click', (e) => {
                    if (!(e.target instanceof HTMLElement)) {
                        return;
                    }
                    const target = e.target;
                    const parent = target.closest(`.${CommonTab.parentClassName}`);
                    if (parent !== null) {
                        let clickedTrg;
                        const siblingTrgs = parent.getElementsByClassName(CommonTab.trgClassName);
                        const relatedConts = parent.getElementsByClassName(CommonTab.contClassName);
                        if (siblingTrgs.length) {
                            for (let j = 0; j < siblingTrgs.length; j++) {
                                if (!(siblingTrgs[i] instanceof HTMLElement)) {
                                    continue;
                                }
                                siblingTrgs[j].classList.remove(CommonTab.toggleClassName);
                            }
                        }
                        if (relatedConts.length) {
                            for (let j = 0; j < relatedConts.length; j++) {
                                if (!(relatedConts[i] instanceof HTMLElement)) {
                                    continue;
                                }
                                relatedConts[j].classList.remove(CommonTab.toggleClassName);
                            }
                        }
                        if (target.classList.contains(CommonTab.trgClassName)) {
                            clickedTrg = target;
                        }
                        else if (target.closest(`.${CommonTab.trgClassName}`) !== null) {
                            clickedTrg = target.closest(`.${CommonTab.trgClassName}`);
                        }
                        else {
                            return;
                        }
                        if (!(clickedTrg instanceof HTMLElement)) {
                            return;
                        }
                        clickedTrg.classList.add(CommonTab.toggleClassName);
                        const clickedTrgOrder = Array.prototype.indexOf.call(siblingTrgs, clickedTrg);
                        relatedConts[clickedTrgOrder].classList.add(CommonTab.toggleClassName);
                    }
                }, false);
            }
        }
    }
    static init() {
        const target = document.getElementsByClassName('js-tab');
        if (target.length) {
            for (let i = 0; i < target.length; i++) {
                if (!(target[i] instanceof HTMLElement)) {
                    continue;
                }
                const thisTarget = target[i];
                new CommonTab(thisTarget);
            }
        }
    }
}
CommonTab.parentClassName = 'js-tab';
CommonTab.trgClassName = 'js-tab__trg';
CommonTab.contClassName = 'js-tab__cont';
CommonTab.toggleClassName = 'is-current';
