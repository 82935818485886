import imagesLoaded from 'imagesloaded';
const objectFitImages = require('object-fit-images');
export class Loading {
    constructor() {
        this.loadedFlag = false;
        this.isRunning = false;
        this.root = document.querySelector('html');
        this.loadingElm = document.getElementById('js-loading');
        this.loadedImg = 0;
        this.imgLength = imagesLoaded('body', { background: true }).images.length;
        this.progressElm = document.getElementById('js-progress');
        this.progressCurrent = 0;
        this.timer = 0;
        this.ofiTarget = document.getElementsByClassName('js-ofi');
        this.start();
    }
    start() {
        if (this.loadingElm) {
            imagesLoaded(this.loadingElm, () => {
                this.timer = window.setInterval(this.monitorProgress.bind(this), 1000 / 50);
                imagesLoaded('body', { background: true }).on('progress', () => {
                    this.loadedImg++;
                });
            });
        }
        else {
            this.clearLoading();
        }
    }
    monitorProgress() {
        const progressPer = (this.loadedImg / this.imgLength) * 100;
        this.progressCurrent += (progressPer - this.progressCurrent) * 0.1;
        if (this.progressElm) {
            this.progressElm.style.width = `${this.progressCurrent}%`;
        }
        if (this.progressCurrent >= 100) {
            this.clearLoading();
        }
        if (this.progressCurrent > 99.9) {
            this.progressCurrent = 100 + 10;
        }
    }
    clearLoading() {
        clearInterval(this.timer);
        if (this.ofiTarget.length) {
            objectFitImages(this.ofiTarget);
        }
        if (this.root) {
            this.root.classList.add('is-loaded');
        }
        if (this.loadingElm) {
            this.loadingElm.classList.add('is-hidden');
        }
        this.loadedFlag = true;
    }
}
