export class SetGnav {
    constructor() {
        if (SetGnav.gnav) {
            this.gnavItem = SetGnav.gnav.getElementsByClassName('js-gnav__item');
            if (this.gnavItem.length) {
                SetGnav.targets = [...this.gnavItem];
            }
            if (SetGnav.trg !== null) {
                SetGnav.targets.push(SetGnav.trg);
            }
        }
        this.bindEvent();
    }
    bindEvent() {
        if (SetGnav.targets.length) {
            for (let i = 0; i < SetGnav.targets.length; i++) {
                new ToggleGnav(SetGnav.targets[i], 'is-active');
            }
        }
    }
}
SetGnav.root = document.querySelector('html');
SetGnav.gnav = document.getElementById('js-gnav');
SetGnav.trg = document.getElementById('js-gnav-trg');
SetGnav.targets = [];
class ToggleGnav {
    constructor(element, className) {
        this.element = element;
        this.className = className;
        this.handleClick();
    }
    handleClick() {
        if (this.element === null) {
            return;
        }
        this.element.addEventListener('click', () => {
            if (this.isGnavItem()) {
                this.deactivate();
            }
            else if (this.isActive()) {
                this.deactivate();
            }
            else {
                this.activate();
            }
        }, false);
    }
    isGnavItem() {
        if (this.element === null) {
            return false;
        }
        if (this.element.classList.contains('js-gnav__item')) {
            return true;
        }
        else {
            return false;
        }
    }
    isActive() {
        if (this.element === null) {
            return false;
        }
        if (this.element.classList.contains(this.className)) {
            return true;
        }
        else {
            return false;
        }
    }
    activate() {
        if (SetGnav.root) {
            SetGnav.root.classList.add('is-locked', 'gnav-is-active');
        }
        if (this.element && this.element instanceof HTMLElement) {
            this.element.classList.add(this.className);
        }
        if (SetGnav.gnav !== null) {
            SetGnav.gnav.classList.add(this.className);
        }
    }
    deactivate() {
        if (SetGnav.root) {
            SetGnav.root.classList.remove('is-locked', 'gnav-is-active');
        }
        if (this.element && this.element instanceof HTMLElement) {
            this.element.classList.remove(this.className);
        }
        if (SetGnav.trg && SetGnav.trg instanceof HTMLElement) {
            SetGnav.trg.classList.remove(this.className);
        }
        if (SetGnav.gnav !== null) {
            SetGnav.gnav.classList.remove(this.className);
        }
    }
}
