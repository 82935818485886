export class SetWidth {
    constructor(wrapper) {
        this.wrapper = wrapper;
        this.width = this.wrapper.getAttribute('data-width');
        this.process();
    }
    process() {
        this.setVal();
        if (SetWidth.mediaQuery != null) {
            SetWidth.mediaQuery.addEventListener('change', this.setVal.bind(this), false);
        }
    }
    setVal() {
        if (this.width !== null && this.width !== '') {
            const widthArray = this.width.split('/');
            if (widthArray.length === 2) {
                const widthVal = widthArray[0];
                const widthValPc = widthArray[1];
                if (SetWidth.mediaQuery != null && SetWidth.mediaQuery.matches) {
                    this.wrapper.style.width = widthValPc;
                }
                else {
                    this.wrapper.style.width = widthVal;
                }
            }
        }
    }
    static init() {
        const target = document.getElementsByClassName('js-set-width');
        if (target.length) {
            for (let i = 0; i < target.length; i++) {
                if (!(target[i] instanceof HTMLElement)) {
                    continue;
                }
                const thisTarget = target[i];
                new SetWidth(thisTarget);
            }
        }
    }
}
SetWidth.mediaQuery = window.matchMedia('screen and (min-width: 768px)');
